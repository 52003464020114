<template>
  <div class="mt-3 cs-container relative">
    <div class="d-print-none">
      <button
        class="btn bg-pri text-white ml-auto d-print-none absolute top-2 right-2 px-3 mx-2 mt-16"
        @click="handlePrint"
      >
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8332 4.66667H13.9998V0.5H3.99984V4.66667H3.1665C1.78317 4.66667 0.666504 5.78333 0.666504 7.16667V12.1667H3.99984V15.5H13.9998V12.1667H17.3332V7.16667C17.3332 5.78333 16.2165 4.66667 14.8332 4.66667ZM5.6665 2.16667H12.3332V4.66667H5.6665V2.16667ZM12.3332 13.8333H5.6665V10.5H12.3332V13.8333ZM13.9998 10.5V8.83333H3.99984V10.5H2.33317V7.16667C2.33317 6.70833 2.70817 6.33333 3.1665 6.33333H14.8332C15.2915 6.33333 15.6665 6.70833 15.6665 7.16667V10.5H13.9998Z"
            fill="white"
          />
          <path
            d="M13.9998 8.41667C14.4601 8.41667 14.8332 8.04357 14.8332 7.58333C14.8332 7.1231 14.4601 6.75 13.9998 6.75C13.5396 6.75 13.1665 7.1231 13.1665 7.58333C13.1665 8.04357 13.5396 8.41667 13.9998 8.41667Z"
            fill="white"
          />
        </svg>
        {{ $t('multidisciplinary_board.lbl_print') }}
      </button>
      <svg
        v-if="healthRecordId"
        width="64"
        height="64"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="handleViewDetail"
        class="absolute top-1/2 -translate-x-1/2 right-4 text-gray-400 hover:text-blue-900 cursor-pointer d-print-none"
      >
        <path
          d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M14.5 11.5L19.5 16L14.5 20.5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div
        @click="handleBack"
        role="button"
        class="absolute top-0 -translate-x-1/2 right-2 mr-2 text-gray-400 hover:text-blue-900 cursor-pointer d-print-none"
      >
        <span
          class="flex w-12 h-12 align-middle border rounded-circle hover:bg-blue-100 transition-all duration-200"
        >
          <i class="el-icon-close m-auto text-2xl" />
        </span>
      </div>
    </div>
    <div class="text-black" v-loading="isLoading">
      <div class="cs-body mx-auto px-14">
        <div class="cs-wrapper">
          <div class="row cs-p-header">
            <div class="col-sm-4 flex flex-col items-center justify-center">
              <img src="/images/logo-bo-y-te.png" alt class="logo-bo-y-te" />
            </div>
            <div class="col-sm-8 flex flex-col items-end justify-center">
              <div class="fs-22 uppercase text-center">
                <div class="text-center font-bold">
                  {{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.authority_in_charge : ''
                  }}
                </div>
                <div class="font-bold">
                  {{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.name
                  : '' }}
                </div>
                <div class="font-bold">{{ $globalClinic && $globalClinic.name }}</div>
              </div>
            </div>
          </div>
          <div class="cs-p-title mt-10 uppercase fs-56 font-bold text-center">
            Sổ khám bệnh
            <br />và theo dõi điều trị ngoại trú
          </div>
          <!-- <div class="uppercase font-normal fs-24 text-center">
            <span>Năm&nbsp;</span>
            <span>{{ currentYear }}</span>
          </div>-->
          <div class="mt-4 fs-20 cs-patient">
            <div class="row">
              <div class="col-sm-12 flex">
                <div class="uppercase font-normal mr-2">Họ Và Tên:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="(person && person.name)" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 flex">
                <span class="uppercase font-normal mr-1">Năm sinh:</span>
                <div class="flex-1 font-bold">
                  <AppContentNone :content="getYear(person && person.birthday)" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 flex">
                <div class="uppercase font-normal mr-1">Giới tính:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="(person && getGender(person.gender))" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="uppercase font-normal mr-1">Địa chỉ:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && getAddress(person)" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="uppercase font-normal mr-1">Số thẻ BHYT:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && person.insurance_number" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cs-body mx-auto px-14 mt-4">
        <div class="cs-wrapper">
          <div class="row cs-p-header">
            <div class="flex flex-col items-center justify-center">
              <div class="font-bold fs-26">THÔNG TIN HÀNH CHÍNH</div>
            </div>
          </div>
          <div class="mt-4 fs-20 cs-patient">
            <div class="row">
              <div class="col-sm-12 flex">
                <div class="font-normal mr-2">Họ và tên:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="(person && person.name)" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 flex">
                <span class="font-normal mr-1">Ngày sinh:</span>
                <div class="flex-1 font-bold">
                  <AppContentNone :content="getPersonInfoString(person)" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 flex">
                <div class="font-normal mr-1">Giới tính:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="(person && getGender(person.gender))" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="font-normal mr-1">Địa chỉ:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && ( getAddress(person)) " />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="font-normal mr-1">Số điện thoại:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && person.phone" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="font-normal mr-1">Quốc tịch:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && person.nationality_name" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="font-normal mr-1">Số thẻ BHYT:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && person.insurance_number" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="font-normal mr-1">Người thân:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && person.emergency_contact" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex">
                <div class="font-normal mr-1">Số ĐT người thân:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="person && person.contact_phone" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import QrcodeVue from 'qrcode.vue'
import AppContentNone from '../../components/Common/AppContentNone.vue'
import AppDateNone from '../../components/Common/AppDateNone.vue'
import appUtils from '../../utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'

export default {
  name: 'DetailCoverPage',
  components: { AppContentNone, AppDateNone, QrcodeVue },
  mixins: [mixinGetPersonInfo],
  data () {
    return {
      person: {},
      isLoading: false
    }
  },
  computed: {
    currentYear () {
      return window.moment().format('YYYY')
    },
    healthRecordId () {
      return this.$route.query.health_record_id || ''
    }
  },
  created () {
    this.getContactDetail()
  },
  methods: {
    handleViewDetail () {
      this.$router.push({
        path: `/health-records/list-patient/${+this.$route.query
          .health_record_id}`
      })
    },
    getYear (birthday) {
      return (
        birthday &&
        (window.moment(birthday).year() ? window.moment(birthday).year() : '')
      )
    },
    getGender (gender) {
      return appUtils.getGender(gender) === 'Khác'
        ? ''
        : appUtils.getGender(gender)
    },
    getAddress (person) {
      const address = person.address ? `${person.address}, ` : ''
      const address_wards_name = person.address_wards_name
        ? `${person.address_wards_name}, `
        : ''
      const address_district_name = person.address_district_name
        ? `${person.address_district_name}, `
        : ''
      const address_province_name = person.address_province_name
        ? `${person.address_province_name} `
        : ''
      return (
        address +
          address_wards_name +
          address_district_name +
          address_province_name || ''
      )
    },
    handlePrint () {
      window.print()
    },
    getPersonInfoString (p) {
      if (!p?.birthday) return ''

      return (
        this.formatDateDMY(p?.birthday) + ' (' + this.getAges(p?.birthday) + ')'
        // +
        // (p.career ? ', ' + p.career : '')
      )
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + this.$t('schedule_page.lbl_age')
    },
    handleBack () {
      //   this.$router.replace({ name: 'ListBenhAn' })
      this.$router.go(-1)
    },
    async getContactDetail () {
      let self = this
      self.isLoading = true
      if (self.$route.params.id) {
        // let clinic_id = this.$clinicId
        try {
          let resp = await self.$rf
            .getRequest('DoctorRequest')
            .getPersonDetail(self.$route.params.id)
          if (resp && resp.data) {
            this.person = resp.data
            self.isLoading = false
          }
        } catch (e) {
          console.log(e)
          self.isLoading = false
        } finally {
          self.isLoading = false
        }
      } else {
        // self.$router.push({ paht: '/doctor/contact-management' })
      }
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.modal {
  padding: 0 !important;
}
.logo-bo-y-te {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.cs-body {
  max-width: 880px;
  width: 100%;
  margin: 40px 0;
}

.cs-wrapper {
  border: 2px solid #000;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.cs-p-title {
  // max-width: 80%;
  margin: 100px auto 140px;
  font-size: 40px;
}

.cs-p-header {
  margin-top: 20px;
}

.cs-info {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-width: 350px;
}

.cs-info-item {
  width: 100%;
  text-align: center;
  padding: 8px;
}

.cs-border-b {
  border-bottom: 1px solid #000;
}

.cs-box {
  border: 1px solid #000;
  padding: 8px;
}

.cs-mt {
  margin-top: 40px;
}

.content-none {
  width: 100%;
  border-bottom: 2px dotted;
}

.cs-font-small {
  font-size: 17px;
}

@media print {
  .cs-body {
    max-width: unset;
    width: 100%;
    margin: 0px;
    height: 970px;
  }

  .cs-body * {
    font-size: 14px !important;
  }

  .cs-wrapper {
    border-width: 1px;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .cs-p-title {
    // max-width: 70%;
    // margin-top: 60px;
    margin: 160px auto 220px;
    font-size: 40px !important;
  }

  .cs-info-item {
    padding: 4px;
  }

  .cs-p-header * {
    font-size: 18px !important;
  }

  .cs-p-data {
    gap: 16px !important;
    margin-top: 80px !important;
  }

  .cs-container {
    margin: 0 !important;
  }

  .cs-patient {
    // margin-top: 60px !important;
    max-width: 90%;
    margin: 0 auto;
  }

  .cs-patient-code {
    gap: 16px !important;
  }

  .content-none {
    border-bottom: 1px dotted;
  }

  .cs-font-small * {
    font-size: 13px !important;
  }

  // .cs-p-data * {
  //   font-size: 18px !important;
  // }
  .top-14 {
    top: 3.5rem;
  }
}
</style>
  