import { render, staticRenderFns } from "./DetailCoverPage.vue?vue&type=template&id=5ae6fa0c&scoped=true"
import script from "./DetailCoverPage.vue?vue&type=script&lang=js"
export * from "./DetailCoverPage.vue?vue&type=script&lang=js"
import style0 from "./DetailCoverPage.vue?vue&type=style&index=0&id=5ae6fa0c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae6fa0c",
  null
  
)

export default component.exports